<template>
  <div>
    <b-embed
      style="height: 1080px"
      type="iframe"
      aspect="16by9"
      src="https://eventseating-bahrainthismonth.tktfox.com"
      allowfullscreen
    ></b-embed>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
